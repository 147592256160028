$(document).ready(function () {
	$('[data-role="slick-slider"][data-variable-width="true"]').slick({
		variableWidth: true,
		centerMode: true,
		slidesToShow: 1,
		dots: false,
		infinite: true,
		responsive: [{
				breakpoint: 768,
				settings: {
					centerMode: true,
					centerPadding: '0',
					slidesToShow: 1,
					variableWidth: false
				}
			},
			{
				breakpoint: 480,
				settings: {
					centerMode: true,
					centerPadding: '0',
					slidesToShow: 1,
					// fade: true,
					variableWidth: false
				}
			}
		]
	});

	// var tabs = $('.tab__contents').children('.tab__target');

   // jQuery.each(jQuery(tabs), function () {
	// 	var $this_tab = $(this).parent('.tab__contents');
	// 	var other_height = $(this).outerHeight() + 'px';
	// 	var auto_height = $this_tab.data('autoheight') || false;

   //    if(auto_height == false){
	// 		$this_tab.attr('style', 'min-height: ' + other_height);
	// 	}
   // });
   
   jQuery.each(jQuery('.no-touch'), function (e) {
      var $this = $(this);

      $(window).on('resize', function () {
         if ($(this).width() < 768) {
            $this.removeClass('no-touch');
         }
		});
		
		$('#boton').on("click", function () {
         $($($(this).parents('.form-parent')).find('.response-hidden')).removeClass('hide');
         $($($(this).parents('.form-parent')).find('.response-showin')).addClass('hide');
      });
	});

	jQuery.each(jQuery('.stickit__deployer.desktop'), function (e) {
		var $this = $(this);

		if(!$this.hasClass('user-handled')){

			if ($(window).width() > 850 && $('body').hasClass('scrolled')) {
				$this.addClass('deployed');
				$($this.data('target')).addClass('deployed');
			}else{
				$this.removeClass('deployed');
				$($this.data('target')).removeClass('deployed');
			}
			
			$(window).on('scroll', function(){

				if(!$this.hasClass('user-handled')){

					if ($(window).width() > 850 && $('body').hasClass('scrolled')) {
						$this.addClass('deployed');
						$($this.data('target')).addClass('deployed');
					}else{
						$this.removeClass('deployed');
						$($this.data('target')).removeClass('deployed');
					}
				}
			});

		}

	});

	$('[data-role="resetear"][data-form="simulacion"]').on('click', function(){
		$('#resultado-final').addClass('sending');

		setTimeout(function () {
			$('#resultado-final').addClass('hide');
			$('#resultado-final').removeClass('sending');

			$('#cotizador').removeClass('hide');
			$('#simulador').addClass('hide');
		}, 1000);
	});
});